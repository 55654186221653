.transactions-container {
    margin: 5px;
    margin-top: 10px;
    display: grid;
    grid-template-columns: 1fr;
    grid-template-rows: default;
    grid-template-areas: 'card1 card2';
    grid-gap: 5px;
    width: 100%;
    grid-template-columns: 1fr;
    grid-template-rows: auto;
    margin-top: 10px;
}

.transactions-container .card-container {
    flex-direction: column;
    padding: 30px;
    background: rgba(0, 0, 0, .1);
    border-radius: 15px;
    display: flex;
    position: relative;
    align-items: flex-start;
}

.transactions-container .card-container .card-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
    margin-bottom: 10px;
}        

.transactions-container .card-container .card-title {
    margin: 0 40px 0 0;
    white-space: nowrap;
    color: white;
}
    
.transactions-container .card-container .transaction-count {
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 100%;
    letter-spacing: -.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
}

.transactions-container .card-container .transaction-grid-container {
    width: 100%;
}

.transactions-container .card-container .transaction-grid-container .grid {
    display: grid;
    grid-template-columns: 1fr 2fr 2fr 1fr 2fr 2fr 1fr 1fr 1fr 1fr;
    font-size: .8rem;
    width: 100%;
}

.transactions-container .card-container .transaction-grid-container .grid .header-cell {
    padding: 12px;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    background: rgba(0, 0, 0, .1);
    text-align: center;
    font-size: .8rem;
    display: flex;
    justify-content: center;
    align-items: center;
    position: relative;
    color: white;
}
.transactions-container .card-container .transaction-grid-container .grid .header-cell strong {
    font-weight: 600;
    margin: 0 3px 0 0;
}
            
.transactions-container .card-container .transaction-grid-container .grid .header-cell:hover {
    cursor: pointer;
    border-top: 2px solid #2a6dff;
}

.transactions-container .card-container .transaction-grid-container .grid .cell {
    padding: 12px;
    border-bottom: 2px solid rgba(0, 0, 0, .2);
    font-size: .9rem;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    color: rgba(255, 255, 255, 0.7);
}
 