.portfolio-card-container {
    flex-grow: 1;
    margin: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 20px;
    min-height: 120px;
    height: fit-content;
    max-width: 400px;
    min-width: 380px;
    background: rgba(141, 84, 117, .1);
    border-radius: 15px;
    position: relative;
    box-sizing: padding-box;
}

.portfolio-card-container .portfolio-card-title {
    color: white;
    font-weight: 500;
    white-space: nowrap;
    display: flex;
    gap: 0.5rem;
    align-items: center;
    margin-bottom: 1.5rem;
}

.portfolio-card-container .portfolio-card-content {
    display: flex;
    gap: 0.5rem;
    flex-direction: column;
    width: 100%;
    margin-bottom: 10px;
}

.portfolio-card-stat {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
}

.portfolio-card-stat .portfolio-card-stat-title {
    color: #b2bfce;
    font-weight: 300;
}

.portfolio-card-stat .portfolio-card-stat-value {
    color: white;
    font-weight: 300;
}

.portfolio-card-container .portfolio-card-button-row {
    display: flex;
    flex-direction: column;
    width: 100%;
    justify-content: flex-end;
    border-top: 1px solid #b2bfce;
    padding-top: 10px;
    gap: 0.5rem;
}

.portfolio-card-container .portfolio-card-button-row .portfolio-card-button {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    padding: 10px 30px;
    background: linear-gradient(90deg, rgba(44, 22, 86, 1) 0%, #7d3560 100%);
    color: white;
    border: none;
    border-radius: 20px;
    font-size: 16px;
    cursor: pointer;
    transition: 0.3s ease all;
}

.portfolio-card-container .portfolio-card-button-row .portfolio-card-button:hover {
    background: linear-gradient(30deg, rgba(44, 22, 86, 1) 0%, #7d3560 100%);
    transition: 0.3s ease all;
  }

.portfolio-card-container .portfolio-card-button-row .portfolio-card-button.disabled {
    background: rgba(255, 255, 255, 0.3);
}