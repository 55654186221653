.myportfolio-container {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}
.myportfolio-container .card-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.myportfolio-container .card-title {
    margin: 0 40px 0 0;
    white-space: nowrap;
    color: white;
}

.myportfolio-container .my-deposit {
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 100%;
    letter-spacing: -.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}
    
.myportfolio-container .card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: 200px;
}

.myportfolio-container .card-content-wrapper {
    padding: 30px 0 0 0;
    width: 100%;
    min-width: 180px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;      
}
