@import url("https://fonts.googleapis.com/css2?family=Poppins:wght@100..900&display=swap");
@import url("https://fonts.googleapis.com/css2?family=Montserrat:wght@100..900&display=swap");

* {
  padding: 0;
  margin: 0;
  box-sizing: border-box;
  font-family: loos-normal, sans-serif;
  /* background: black;
  color: white; */
}

body {
  background: black !important;
  color: white !important;
}

.custom-navbar {
  background: rgba(0, 0, 0, 0.4);
  /* box-shadow: 0 0px 4px rgba(0, 0, 0, 0.5) !important; */
  padding: 10px 5rem !important;
}

.nav-link {
  font-size: 1.1rem !important;
  font-weight: 600 !important;
  color: white !important;
}

.nav-link:hover {
  color: #7d3560 !important;
  background: white;
  border-radius: 4px;
}

.navbar-brand img {
  width: 200px;
}

.hero-section {
  min-height: 100vh;
  color: white;
  display: flex;
  align-items: center;
  position: relative;
  background-color: #000;
  overflow: hidden;
}

.video-background {
  position: absolute;
  top: 0;
  left: 20%;
  width: 100%;
  height: 100%;
  overflow: hidden;
  z-index: 1;
}

.video-background video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.hero-content {
  max-width: 45rem !important;
  position: relative;
  z-index: 1;
  /* text-align: center; */
}

.hero-section h1 {
  font-size: 3rem;
  font-weight: bold;
  text-align: left;
}

.hero-section .subheading {
  font-size: 1.5rem;
  margin-top: 20px;
  margin-bottom: 20px;
  text-align: left;
  font-weight: 800;
}

.hero-section p {
  font-size: 1.2rem;
  margin: 20px 0;
  text-align: left;
}

.hero-buttons {
  text-align: left;
}

.hero-buttons .btn {
  margin: 10px 10px 10px 0;
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  min-width: 12rem;
}

.nav-item .btn {
  padding: 10px 20px;
  border-radius: 8px;
  font-size: 1rem;
  font-weight: bold;
  min-width: 12rem;
  /* color: white; */
  border: none;
}

.nav-item .btn:hover {
  color: white;
}

.btn-first {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: white !important;
  border: none;
  display: flex !important;
  align-items: center;
  justify-content: center;
}

.btn-first:hover {
  color: black !important;
}

.hero-buttons .btn-secondary {
  background-color: transparent;
  color: white;
  border: 2px solid white;
}

.impact-container {
  /* font-family: Arial, sans-serif;
  color: #333; */
  position: relative;
}

.impact-container-image {
  background: url("./assets/imgs/impact-bg.png") no-repeat center;
  background-size: cover;
}

.impact-container .impact-background {
  margin-top: 15rem;
  width: 100%;
  z-index: -1;
}

.governance-proposals {
  display: flex;
  justify-content: center;
  padding: 0rem 2rem;
}

.governance-proposals-box {
  width: 70%;
  background-color: #fff;
  padding: 4rem;
  border-radius: 8px;
  margin-bottom: 20px;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.governance-proposals h1 {
  color: rgb(92 44 184);
  font-size: 3.1rem;
  font-weight: 800;
}

.governance-proposals p {
  font-size: 1.2rem;
}

.proposal {
  padding: 10px;
  border: 1px solid rgb(196 192 255);
  border-radius: 4px;
  margin-bottom: 10px;
}

.proposal h3 {
  font-size: 1.6rem;
  font-weight: 600;
  margin-bottom: 0.3rem;
  color: rgb(92 44 184);
}

.proposal p {
  font-size: 16px;
  padding: 2px 6px;
  border-radius: 4px;
}

.closed {
  color: #f44336;
}

.view-all-button {
  text-align: center;
}

.view-all-button button {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-weight: 600;
  font-size: 18px;
}

.community-stats {
  /* background-color: #2b8a3e; */
  /* color: #fff; */
  padding: 40px 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.community-stats h1 {
  margin-bottom: 30px;
  font-size: 3rem;
}

.stats {
  width: 100%;
  padding: 0rem 10rem;
  display: flex;
  flex-direction: column;
  gap: 1rem;
}

.stats .stat1 {
  width: 100%;
}

.stats .stat2 {
  width: 100%;
}

.stats .stat {
  text-align: center;
  padding-top: 1rem;
}

.stats p {
  line-height: 1.5rem;
}

.stats h3 {
  font-size: 2.5rem;
  font-weight: 700;
}

.apply-grant-button {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: #fff;
  padding: 10px 30px;
  border: none;
  border-radius: 4px;
  cursor: pointer;
  font-size: 1.2rem;
  font-weight: 700;
  margin-top: 3rem;
}

.big-green-impact {
  padding: 0px 20px;
  border-radius: 8px;
  /* box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); */
  text-align: center;
}

.big-green-impact h2 {
  font-size: 3rem;
  color: #8c61be;
  font-weight: 700;
}

.big-green-impact h1 span {
  font-size: 4rem;
  font-weight: 800;
  color: #000;
}

.big-green-impact p {
  font-size: 1.2rem;
  padding: 0 2rem;
  margin-bottom: 0;
}

.community-grant-section {
  text-align: center;
  width: 100%;
  display: flex;
  justify-content: center;
}

.community-grant-section-inside {
  margin: 10rem;
  width: 60%;
}

.community-grant-section h1 {
  font-size: 4rem;
  font-weight: 800;
  color: #005705;
  margin-bottom: 1.5rem;
}

.community-grant-section p {
  font-size: 1.3rem;
  margin-bottom: 2rem;
}

.community-grant-section .cards {
  display: flex;
  justify-content: center;
  gap: 1.5rem;
  text-align: left !important;
}

.community-grant-section .cards .card {
  border: 1px solid #c9c6ff;
  display: flex;
  /* align-items: center; */
  padding: 1.5rem;
  padding-left: 1rem;
  padding-right: auto;
  width: 20rem;
  transition: 0.3s ease all;
  box-shadow: 0 6px 4px rgba(192, 192, 192, 0.5) !important;
}

.community-grant-section .cards .card h2 {
  font-weight: 700;
  font-size: 1.5rem;
}

.community-grant-section .cards .card p {
  font-size: 0.9rem;
}

.community-grant-section .cards .card p span {
  font-weight: 700;
}

.community-grant-section .cards .card:hover {
  background: rgb(247 247 252);
  cursor: pointer;
  scale: 1.04;
  transition: 0.3s ease all;
}

.membership-resources {
  /* background: url('./HomeSectionComponents/membership-banner.webp') no-repeat center; */
  /* background-size: cover; */
  padding: 5rem 0px;
  text-align: center;
  color: white;
}

.membership-resources .content {
  max-width: 70%;
  margin: 0 auto;
  padding: 20px;
  border-radius: 10px;
}

.membership-resources h1 {
  font-size: 2.8rem;
  font-weight: 700;
  margin-bottom: 20px;
  color: white;
}

.membership-resources h2 {
  font-weight: 700;
  font-size: 3rem;
  color: #8c61be;
}

.membership-resources p {
  font-size: 1.2em;
  margin-bottom: 30px;
  color: white;
}

.membership-resources .buttons {
  display: flex;
  justify-content: center;
  flex-wrap: wrap;
  gap: 3rem;
}

.membership-resources a {
  text-decoration: none;
  color: white;
}

.membership-resources .buttons-div {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 10px;
  /* border: 2px solid red; */
}

.footer {
  /* background: url("./assets/imgs/Footer_BG.png") no-repeat center center; */
  background-size: cover;
  color: #fff;
  padding: 40px 20px;
  text-align: left;
}

.footer-container {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.footer-column {
  flex: 1;
  min-width: 200px;
  padding: 20px;
}

.footer-logo img {
  width: 200px;
  margin-bottom: 20px;
}

.footer-column h4 {
  font-size: 18px;
  color: #fff;
  margin-bottom: 10px;
}

.footer-column ul {
  list-style: none;
  padding: 0;
}

.footer-column a {
  color: #fff;
  text-decoration: none;
}

.footer-column a:hover {
  text-decoration: underline;
}

.footer-column ul li {
  margin-bottom: 10px;
  font-size: 14px;
  color: #b3b3b3;
}

.contact-info p {
  display: flex;
  align-items: center;
  margin: 5px 0;
}

.contact-info p i {
  margin-right: 10px;
}

.subscribe-form {
  display: flex;
  flex-direction: column;
}

.subscribe-form input {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.subscribe-form button {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: white;
  padding: 10px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.footer-bottom {
  width: 100%;
  border-top: 1px solid #b3b3b3;
  padding-top: 20px;
  margin-top: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
}

.footer-bottom-links {
  width: 100%;
  max-width: 1200px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  flex-wrap: wrap;
}

.footer-bottom-left span,
.footer-bottom-right svg {
  margin: 5px 10px;
  font-size: 14px;
  color: #b3b3b3;
}

.footer-bottom-right svg {
  font-size: 20px;
  cursor: pointer;
}

.footer-bottom-left span svg {
  margin-right: 5px;
}

.footer-bottom-right svg:hover {
  color: white;
}

.copyright-text {
  font-size: 0.9rem;
  color: #b3b3b3;
  margin-top: 20px;
}

.footer-column p {
  font-size: 14px;
  color: #b3b3b3;
}

.footer-column p a {
  color: #b3b3b3;
  text-decoration: none;
}

.footer-column p a:hover {
  text-decoration: underline;
}

.grant-hero-section {
  /* background: linear-gradient(90deg, rgba(44, 22, 86, 1) 0%, #7d3560 100%); */
  /* background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%); */
}

.timeline-container {
  background-color: #f8f5ed;
  padding: 50px 20px;
}

.timeline-container h1 {
  font-size: 3rem;
  color: rgb(92 44 184);
  font-weight: 700;
  margin-bottom: 50px;
  text-align: center;
}

.timeline {
  position: relative;
  max-width: 800px;
  margin: 0 auto;
  text-align: center;
}

.powered-by {
  font-size: 1em;
  margin-top: 50px;
  color: #333;
}

.proposals-container {
  /* display: flex; */
  background-color: #000000;
  padding-top: 2rem;
}

.proposals-container2 {
  display: flex;
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  margin: 3rem;
  padding: 2rem 3rem;
}

.top-bar {
  display: flex;
  justify-content: space-between;
  border-bottom: 2px solid #fff;
  padding: 2rem 4rem 0px 4rem;
  margin: 3rem;
}

.top-bar .status {
  padding: 0;
}

.top-bar .status button {
  font-size: 1.2rem;
  font-weight: 600;
  padding: 10px 20px;
  cursor: pointer;
  border: none;
  background: transparent;
  transition: all 0.3s ease;
  border-bottom: 5px solid transparent;
  color: white;
}

.top-bar .status button:hover {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: white;
  z-index: 111;
  transition: all 0.3s ease;
}

.top-bar .status button.active {
  border-bottom: 5px solid rgb(92 44 184);
}

.top-bar .submit-new-proposal {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: white;
  font-size: 1.1rem;
  font-weight: 600;
  padding: 0px 2rem;
  border-radius: 5px;
  border: none;
  margin-bottom: 8px;
  transition: all 0.3s ease;
}

.top-bar .submit-new-proposal:hover {
  transition: all 0.3s ease;
}

.sidebar {
  width: 150px;
  padding: 2rem;
  /* background-color: #f4f4f4; */
}

.proposals-container2 .sidebar h6 {
  color: white;
  /* text-align: center; */
  margin-bottom: 10px;
  font-weight: 700;
}

.sidebar h3 {
  margin-bottom: 10px;
}

.sidebar button {
  display: block;
  width: 100%;
  margin-bottom: 5px;
  padding: 10px;
  border: none;
  background: transparent;
  cursor: pointer;
  text-align: left;
  display: flex;
  align-items: center;
  gap: 1rem;
  border-radius: 5px;
  color: white;
}

.sidebar button:hover {
  background: rgb(225 238 248);
  color: black;
}

.sidebar button.active {
  background-color: rgb(255 255 255);
  color: black;
}

.proposals {
  flex: 1;
  padding: 20px;
}

.proposals .proposal {
  border: 1px solid #ccc;
  padding: 15px;
  margin: 10px 0;
  background-color: white;
  box-shadow: 4px 4px 4px rgba(202, 202, 202, 0.5) !important;
}

.proposals .proposal h6 {
  font-weight: 700;
}

.proposals .proposal .proposal-category span {
  border-radius: 20px;
  padding: 3px 10px;
}

.proposal-status {
  border-radius: 20px;
  width: fit-content;
  padding: 1px 10px;
  background: #333333;
  color: white;
}

.proposals .proposal .proposal-progress-bar {
  background-color: rgba(163, 163, 163, 1);
  overflow: hidden;
  width: 100%;
  height: .375rem;
  display: flex;
}

.proposals .proposal .proposal-progress-bar .bg-success {
  background-color: rgba(22, 189, 159, 1);
  height: 100%;
}

.proposals .proposal .proposal-progress-bar .bg-white {
  background-color: rgba(255, 255, 255, 1);
  height: 100%;
}

.proposals .proposal .proposal-progress-bar .bg-danger {
  background-color: rgba(255, 109, 144, 1);
  height: 100%;
}

.proposals .proposal .proposal-index {
  background: #333333;
  color: white;
  padding: 1px 12px;
  border-radius: 20px;
}

.proposal-status.active {
  background: #1D4BAF;
}

.proposals-pagination {
  display: flex !important;
  justify-content: center !important;
}

.proposals-pagination-top {
  display: flex !important;
  justify-content: end !important;
}

.pagination {
  display: flex;
  list-style: none;
  padding: 0;
  gap: 0.5rem;
}

.pagination li {
  display: inline;
}

.pagination li a {
  padding: 0.25rem 0.5rem;
  /* border: 1px solid #ccc; */
  color: #000;
  text-decoration: none;
  cursor: pointer;
}

.pagination li.active a {
  background-color: rgb(92 44 184);
  color: #fff;
  border: 1px solid rgb(92 44 184);
}

.pagination li a:hover {
  background-color: #eee;
}

.pagination li.previous a,
.pagination li.next a {
  color: #000;
}

.select-proposal {
  /* background: linear-gradient(90deg, #3C2A5F 0.62%, #945276 100%); */
  padding: 8rem 5rem !important;
}

.proposal-container {
  /* background: #1e1f1f; */
  padding: 5rem 8rem;
  border-radius: 10px;
  display: flex;
  /* flex-direction: column; */
}

.vote-proposal {
  font-family: Arial, sans-serif;
  margin: 20px auto;
  padding: 20px;
  max-width: 800px;
  border: 1px solid #ccc;
  border-radius: 8px;
  background-color: #f9f9f9;
}

.vote-proposal .title {
  font-size: 24px;
  font-weight: bold;
  margin-bottom: 10px;
}

.vote-proposal .summary {
  font-size: 16px;
  margin-bottom: 20px;
  color: #666;
}

.vote-proposal .info-section,
.vote-proposal .results-section {
  margin-top: 20px;
  padding: 10px;
  border-top: 1px solid #ddd;
}

.vote-proposal .info-section h2,
.vote-proposal .results-section h2 {
  font-size: 18px;
  margin-bottom: 10px;
}

.vote-proposal .info-section p,
.vote-proposal .results-section p {
  font-size: 14px;
  line-height: 1.6;
  margin: 5px 0;
}

.vote-proposal strong {
  font-weight: bold;
}

.proposal-header {
  text-align: left;
}

.back-button {
  display: flex;
  align-items: center;
  background: white;
  border: 2px solid rgb(209 213 219);
  font-size: 24px;
  cursor: pointer;
  font-weight: 800 !important;
  /* margin-bottom: 10px; */
  padding: 0.5rem;
  border-radius: 0.25rem;
}

.proposal-header p {
  width: 80%;
  font-size: 1.2rem;
}

.proposal-types h2 {
  font-size: 1.3rem;
  font-weight: 700;
}

.proposal-options {
  display: flex;
  gap: 2rem;
}

.proposal-card {
  flex: 1;
  border-radius: 10px;
  padding: 2rem;
  box-shadow: 0 2px 4px rgba(0, 0, 0, 0.1);
  text-align: center;
}

.proposal-card:hover {
  cursor: pointer;
  box-shadow: 0 20px 25px -5px rgba(0, 0, 0, 0.1),
    0 8px 10px -6px rgba(0, 0, 0, 0.1);
}

.proposal-card h3 {
  font-size: 1.5rem;
  line-height: 2rem;
  font-weight: 700;
  margin-bottom: 10px;
  color: white;
}

.proposal-card p {
  font-size: 1rem;
  font-weight: 500;
  color: white;
}

.proposal-card.governance {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
}

.proposal-card.grantmaking {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
}

.proposal-card.krizzar {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
}

.proposal-card.membership {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
}

.create-proposal-container {
  width: 80%;
  padding: 20px;
  border-radius: 8px;
}

.voting-info-container {
  display: flex;
  flex-direction: row;
  width: 100%;
  justify-content: space-between;
  gap: 20px;
}

.voting-info-information {
  background-color: transparent;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  width: 100%;
}

.voting-info-information .title {
  padding-bottom: 0;
  padding: 16px;
  justify-content: space-between;
  display: flex;
}

.voting-info-information .title h4 {
  font-weight: 600;
  color: #111111;
  font-size: 20px;
  line-height: 28px;
}

.voting-info-information .content {
  padding: 16px;
}

.voting-info-information .content div {
  display: flex;
  justify-content: space-between;
  margin-bottom: 10px;
}

.voting-info-information .content div b {
  font-weight: 600;
}

.voting-info-information .content div span {
  color: #444444;
}

.voting-info-result {
  background-color: transparent;
  border-color: #e0e0e0;
  border-width: 1px;
  border-style: solid;
  border-radius: 1rem;
  width: 100%;
}

.voting-info-result .title {
  padding-bottom: 0;
  padding: 16px;
  justify-content: space-between;
  display: flex;
}

.voting-info-result .title h4 {
  font-weight: 600;
  color: #111111;
  font-size: 20px;
  line-height: 28px;
}

.voting-info-result .content {
  padding: 16px;
}

.vote-item {
  margin-bottom: 10px;
}

.vote-item .vote-item-title {
  color: #444444;
  justify-content: space-between;
  display: flex;
  margin-bottom: 4px;
}

.vote-item .vote-item-title .title-left {
  display: flex;
  overflow: hidden;
  text-overflow: ellipsis;
  white-space: nowrap;
  margin-right: 4px;
}

.vote-item .vote-item-title .title-right {
  justify-content: flex-end;
  display: flex;
  white-space: nowrap;
}

.vote-item .vote-item-progress {
  border-radius: 9999px;
  overflow: hidden;
  height: 8px;
  display: flex;
  position: relative;
  flex-direction: row;
}

.vote-item .vote-item-progress .progress-full {
  background-color: #000000;
  height: 100%;
  z-index: 10;
}

.vote-item .vote-item-progress .progress-empty {
  background-color: #000000;
  height: 100%;
  z-index: 10;
  opacity: .2;
}

.vote-item .vote-item-progress .progress-placeholder {
  background-color: #e0e0e0;
  width: 100%;
  height: 100%;
  position: absolute;
}

.create-proposal-header {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
  gap: 1rem;
}

.create-proposal-header-text {
  font-size: 3rem;
  font-weight: 700;
  color: #8c61be;
}

.governance-badge {
  width: fit-content;
  border-radius: 20px;
  padding: 1px 10px;
  background-color: #83aa9c;
  border: 1px solid #417964;
  color: black;
}

.grantmaking-badge {
  width: fit-content;
  border-radius: 20px;
  padding: 1px 10px;
  background-color: #9eb7ea;
  border: 1px solid #658ae6;
  color: black;
}

.membership-badge {
  width: fit-content;
  border-radius: 20px;
  padding: 1px 10px;
  background-color: #f0ab83;
  border: 1px solid #ee8c56;
  color: black;
}

.krizzar-badge {
  width: fit-content;
  border-radius: 20px;
  padding: 1px 10px;
  background-color: #f0ab83;
  border: 1px solid #ee8c56;
  color: black;
}

.create-proposal-information {
  display: flex;
  flex-direction: column;
  gap: 10px;
}

.create-vote-information {
  display: flex;
  flex-direction: row;
  gap: 10px;
}

.create-proposal-information .proposal-info {
  font-size: 1.2rem;
}

.create-proposal-type,
.create-proposal-input-group,
.create-proposal-choices,
.create-proposal-voting-period {
  margin-bottom: 20px;
}

.create-proposal-choices,
.create-proposal-voting-period {
  /* padding: 2.5rem !important;
  background: rgb(250 250 250); */
  /* border-radius: 10px; */
}

.create-proposal-stake {
  display: flex;
  flex-direction: row;
}

.create-proposal-stake-input {
  margin-right: 15px;
}

.create-proposal-container label {
  display: block;
  margin-bottom: 8px;
  font-weight: 800;
  font-size: 1.2rem;
}

.create-proposal-container label span {
  font-size: 0.9rem;
  font-weight: 500;
}

.create-proposal-container [type="text"],
input[type="datetime-local"],
textarea,
select {
  width: 100%;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 4px;
  /* font-size: 14px; */
}

.logo-title {
  color: white;
}

.create-proposal-container textarea {
  resize: vertical;
}

.preview-and-edit-button {
  text-align: end;
}

.preview-and-edit-button button {
  background-color: white;
  border: 1px solid #ccc;
  padding: 4px 8px;
}

.create-proposal-choices {
  margin-bottom: 20px;
  margin-top: 20px;
}

.create-proposal-choice {
  display: flex;
  align-items: center;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  background: white;
  border-radius: 30px;
  padding: 0rem 1rem !important;
}

.create-proposal-choice input {
  flex-grow: 1;
  border: none !important;
  outline: none;
  border-radius: 30px !important;
}

.create-proposal-remove-choice-button {
  padding: 2px 5px;
  background: transparent;
  border: none;
  border-radius: 4px;
  cursor: pointer;
}

.create-proposal-remove-choice-button:hover {
  background-color: #d7d7d7;
}

.create-proposal-add-choice-button {
  display: flex;
  justify-content: end;
}

.create-proposal-add-choice-button button {
  display: block;
  margin-top: 10px;
  padding: 6px 9px;
  border: 1px solid #ccc;
  background-color: white;
  border-radius: 50%;
  cursor: pointer;
  color: orange;
}

.create-proposal-period-selection {
  display: flex;
  gap: 2rem;
}

.create-proposal-period-selection .selection-item {
  flex: 1;
}

.create-proposal-period-selection label {
  font-weight: 700;
}

.create-proposal-submit-button {
  display: block;
  padding: 10px 30px;
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: white;
  border: none;
  border-radius: 4px;
  font-size: 16px;
  cursor: pointer;
  transition: 0.3s ease all;
}

.create-proposal-submit-button.approve {
  margin-right: 15px;
}

.create-proposal-submit-button:hover {
  background-color: rgb(115 148 92);
  transition: 0.3s ease all;
}

.donate-now-container {
  min-height: 100vh;
  display: flex;
  align-items: center;
  justify-content: center;
}

.donate-now-inside-container {
  width: 60%;
  text-align: center;
}

.donate-now-inside-container h1 {
  font-size: 4rem;
  font-weight: 800;
}

.donate-now-inside-container h3 {
  font-size: 2.3rem;
}

.donate-now-form-container {
  padding-top: 5rem;
  padding-bottom: 5rem;
  /* background: url("./assets/imgs/donate-now.png") no-repeat center; */
  background-size: cover;
}

.payment-form {
  font-family: Arial, sans-serif;
  max-width: 900px;
  margin: 5rem auto;
  padding: 3rem;
  /* background: rgba(47, 47, 47, 0.5); */
  border-radius: 20px;
}

.payment-form-header h1 {
  color: #8c61be;
  font-weight: 800;
}

.payment-form-header p {
  font-size: 1.2rem !important;
}

.payment-form .card-input {
  display: flex;
  flex-direction: column;
  margin-bottom: 15px;
}

.payment-form .card-input label {
  margin-bottom: 5px;
  font-weight: bold;
}

.payment-form .card-input input[type="text"] {
  padding: 10px;
  margin-bottom: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.payment-form p {
  font-size: 14px;
  margin-bottom: 15px;
}

.payment-form .cover-fees {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.payment-form .cover-fees input[type="checkbox"] {
  margin-right: 10px;
}

.payment-form .give-now {
  background-color: #8c61be;
  color: #fff;
  padding: 15px 30px;
  border: none;
  border-radius: 5px;
  font-size: 16px;
  cursor: pointer;
  margin-bottom: 15px;
}

.payment-form .give-now:hover {
  background-color: #2d3e75;
}

.payment-form .donation-type {
  display: flex;
  justify-content: space-between;
  margin-bottom: 15px;
}

.payment-form .donation-type button {
  flex: 1;
  padding: 10px;
  margin: 0 5px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.payment-form .donation-type button.active {
  background-color: #8c61be;
  color: #fff;
}

.payment-form .donation-frequency {
  margin-bottom: 15px;
  display: flex;
  justify-content: center;
}

.payment-form .donation-frequency label {
  font-weight: bold;
}

.payment-form .donation-frequency select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  margin-top: 5px;
}

.payment-form .donation-amount {
  display: flex;
  justify-content: center;
  margin-bottom: 15px;
  gap: 1rem;
}

.payment-form .donation-amount button {
  min-width: fit-content;
  padding: 15px 30px;
  border: 1px solid #ccc;
  border-radius: 5px;
  background-color: #f2f2f2;
  cursor: pointer;
}

.payment-form .donation-amount input {
  width: 150px !important;
  padding: 10px 20px;
  outline: none;
}

.payment-form .donation-amount button.active {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: #fff;
}

.navbar-nav .dropdown-menu {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%) !important;
}

.navbar-nav .dropdown-menu li a {
  color: white !important;
}
.navbar-nav .dropdown-menu li a:hover {
  color: black !important;
}

.payment-form .donation-amount select {
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.payment-form .donor-info {
  margin-top: 20px;
}

.payment-form .donor-info h3 {
  font-size: 20px;
  margin-bottom: 15px;
}

.payment-form .donar-input-group {
  margin-bottom: 15px;
  display: flex;
  flex-direction: column;
}

.payment-form .donar-input-group label {
  display: block;
  font-weight: bold;
  margin-bottom: 5px;
}

.payment-form .donar-input-group input[type="text"],
.payment-form .donar-input-group input[type="number"],
.payment-form .donar-input-group input[type="email"] {
  /* width: 100%; */
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
}

.payment-form .donar-input-group input[type="checkbox"] {
  margin-right: 10px;
}

.payment-form .payment-info {
  margin-bottom: 20px;
}

.payment-form .card-details {
  display: flex;
  justify-content: space-between;
  margin-bottom: 20px;
}

.payment-form .card-input {
  flex: 2;
  margin-right: 10px;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-form .expiry-input,
.payment-form .cvc-input {
  flex: 1;
  padding: 10px;
  font-size: 1rem;
  border: 1px solid #ccc;
  border-radius: 4px;
}

.payment-form .terms {
  font-size: 0.9rem;
  margin-bottom: 10px;
  color: #555;
}

.payment-form .terms a {
  color: #007bff;
  text-decoration: none;
}

.payment-form .cover-fees {
  display: flex;
  align-items: center;
  margin-bottom: 20px;
}

.payment-form .cover-fees input {
  margin-right: 10px;
}

.payment-form .give-now {
  /* width: 100%; */
  padding: 15px;
  background-color: rgb(67, 87, 173);
  color: #fff;
  border: none;
  border-radius: 4px;
  font-size: 1rem;
  cursor: pointer;
}

.payment-form .donation-summary {
  margin-top: 20px;
  font-size: 1.2rem;
  font-weight: bold;
  text-align: center;
}

.payment-form .donation-summary span {
  color: rgb(67, 87, 173);
}

.payment-form .powered-by {
  font-size: 0.8rem;
  color: #888;
  text-align: center;
  margin-top: 20px;
}

.security-section {
  /* background: url("./assets/imgs/SecuritySectionBG.png") no-repeat center center; */
  background-size: cover;
  color: #fff;
  text-align: center;
  padding: 2rem 1rem !important;
}

.security-section h2 {
  font-size: 3rem;
  margin-bottom: 1rem;
  font-weight: 800;
  color: #8c61be;
}

.security-section p {
  font-size: 1.2rem;
  margin-bottom: 2rem;
}

.features {
  display: flex;
  justify-content: center;
  gap: 1rem;
  flex-wrap: wrap;
}

.feature-card {
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  color: #fff;
  border-radius: 8px;
  padding: 1.5rem;
  flex: 1;
  max-width: 400px;
  text-align: center;
  box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1);
}

.feature-card.bg-black {
  background: black !important;
}

.feature-card-image img {
  /* max-width: 50px; */
  margin-bottom: 1rem;
}

.feature-card h3 {
  font-size: 1.25rem;
  font-weight: 600;
  margin-bottom: 0.5rem;
}

.feature-card p {
  font-size: 1rem;
}

.alignment-one {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row-reverse;
  padding: 2rem;
  background-color: transparent;
}

.alignment-one h2,
.alignment-two h2 {
  font-size: 2.5rem;
  margin-bottom: 1rem;
}

.alignment-one-text {
  max-width: 50%;
  padding-left: 3rem;
}

.alignment-two {
  display: flex;
  align-items: center;
  justify-content: space-between;
  padding: 2rem;
  background-color: transparent;
}

.alignment-two .alignment-one-text {
  max-width: 50%;
  padding-left: 0;
}

.why-choose-us-heading {
  padding-top: 5rem;
  text-align: center;
  font-size: 2.8rem;
  margin-bottom: 1rem;
}

.alignment-one-text li {
  font-size: 1.1rem;
}

.alignment-one-text a {
  font-weight: 600;
  text-decoration: none;
  border-bottom: 1px solid #000;
  color: #000;
}

.alignment-one-text .btn {
  font-size: 1rem !important;
  padding: 0.75rem 1.5rem !important;
  margin-bottom: 1rem;
}

.alignment-one-image {
  max-width: 45%;
}

.alignment-one-image img {
  width: 100%;
  height: auto;
  border-radius: 10px;
}

.terms-of-service {
  margin: 7rem 5rem;
}

.terms-of-service h1 {
  text-align: center;
  cursor: auto;
  font-weight: 700;
  color: #8c61be;
  font-size: 3rem;
}

.terms-of-service h3 {
  font-weight: 700;
  background: #8c61be;
}

.checkout__step-header {
  display: flex;
  align-items: center;
  justify-content: space-between;
  background: transparent;
  padding: 10px 0px;
  cursor: pointer;
  margin-bottom: 10px;
}

.checkout__step-header h5 {
  font-weight: 700;
}

.checkout__step-number,
.checkout__step-number span {
  color: white;
  background: linear-gradient(89.92deg, #a769d4 0%, #cb1dd8 70%, #cb1dd8 100%);
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  height: 40px;
  width: 40px;
  font-weight: bold;
  border: 3px solid white;
}

.token-price {
  position: fixed;
  width: fit-content;
  right: 20px;
  bottom: 20px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
  gap: 10px;
  border: 1px solid white;
  border-radius: 10px;
  /* background: linear-gradient(
    45deg,
    rgba(255, 255, 255, 1) 0%,
    rgba(54, 199, 255, 1) 20%,
    rgba(116, 109, 255, 1) 40%,
    rgba(226, 14, 85, 1) 60%,
    rgba(42, 109, 255, 1) 80%
  ); */
  background: linear-gradient(90deg, rgb(92 44 184) 0%, #7d3560 100%);
  background-size: 300% 300%;
  animation: shine-effect 3s infinite linear;
  z-index: 50;
}

.token-price .title,
.token-price .price {
  color: rgba(255, 255, 255, 1);
  font-size: 1rem;
  padding: 10px;
  font-weight: 700;
  display: flex;
  justify-content: center;
  align-items: center;
}

.token-price .title {
  font-weight: 500;
}

@keyframes shine-effect {
  0% {
    background-position: 0% 50%;
  }

  50% {
    background-position: 100% 50%;
  }

  100% {
    background-position: 0% 50%;
  }
}


/* General container styling */
.staking-page-hero-section {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  padding: 10rem 5rem 0rem 5rem;
  background: url('/public/images/staking-hero-bg.png');
  color: white;
  background-repeat: no-repeat;
  background-size: cover;
}

/* Gradient text */
.staking-page-gradient-text {
  background: linear-gradient(90deg, #5f63ff, #c058f3);
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

/* Main title */
.staking-page-hero-section h1 {
  font-size: 5rem;
  font-weight: bold;
  margin-bottom: 20px;
  line-height: 1.2;
}

/* Subtext paragraph */
.staking-page-hero-section p {
  font-size: 1.2rem;
  margin-bottom: 40px;
  color: #b0b0b0;
  /* Light gray color for the paragraph */
}

/* Call-to-action button */
.staking-page-cta-button {
  background: linear-gradient(90deg, #5f63ff, #c058f3);
  color: white;
  font-size: 1rem;
  font-weight: bold;
  padding: 15px 30px;
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 4px 6px rgba(0, 0, 0, 0.2);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.staking-page-cta-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 6px 8px rgba(0, 0, 0, 0.3);
}

.staking-page-image {
  position: relative;
  z-index: 1;
  margin-top: 30px;
}

.staking-page-image img {
  max-width: 100%;
  height: auto;
  display: block;
  margin: 0 auto;
  border-radius: 8px;
  border: 20px solid black;
  border-bottom: 0px;
  box-shadow: 0 8px 15px rgba(0, 0, 0, 0.3);
}

.separator-top {
  position: relative;
}

.separator-top img {
  width: 100%;
  position: absolute;
  top: -70px;
  z-index: 999;
}

/* Container section */
.how-it-works-section {
  padding: 60px 20px;
  background-color: #070711;
  text-align: center;
  color: white;
}

.how-it-works-title {
  font-size: 2rem;
  margin-bottom: 40px;
  color: #ffffff;
}

/* Outer box */
.how-it-works-box {
  background-color: #181730;
  padding: 40px 20px;
  border-radius: 12px;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  position: relative;
  display: inline-block;
  max-width: 1000px;
  margin: 0 auto;
}

.how-it-works-header {
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
}

.how-it-works-header .badge {
  display: inline-block;
  background-color: #5f63ff;
  color: white;
  font-size: 0.9rem;
  font-weight: bold;
  text-transform: uppercase;
  padding: 6px 12px;
  border-radius: 20px;
  margin-bottom: 16px;
}

/* Vertical steps container */
.steps-container-vertical {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  gap: 2rem;
}

/* Individual step */
.step {
  display: flex;
  align-items: start;
  gap: 20px;
  position: relative;
}

.step-content {
  text-align: left;
}

.step-content h3 {
  color: white;
  font-size: 1.2rem;
  margin-bottom: 8px;
}

.step-content p {
  color: #b0b0b0;
  font-size: 0.9rem;
  margin: 0;
}

/* Connecting circles */
.circle {
  width: 12px;
  height: 12px;
  background-color: #5f63ff;
  border-radius: 50%;
  z-index: 2;
  flex-shrink: 0;
  margin-top: 6.5px;
}

/* Vertical connecting line */
.vertical-line {
  position: absolute;
  width: 4px;
  height: 100px;
  background-color: #5f63ff;
  margin-top: 1rem;
  margin-left: 4px;
}

/* Start staking button */
.start-staking-button {
  margin-top: 30px;
  padding: 15px 30px;
  font-size: 1rem;
  color: white;
  background: linear-gradient(90deg, #5f63ff, #c058f3);
  border: none;
  border-radius: 8px;
  cursor: pointer;
  box-shadow: 0px 6px 12px rgba(0, 0, 0, 0.4);
  transition: transform 0.2s ease, box-shadow 0.2s ease;
}

.start-staking-button:hover {
  transform: scale(1.05);
  box-shadow: 0px 8px 16px rgba(0, 0, 0, 0.5);
}

.what-staking {
  padding: 60px 5rem;
  background-color: #070711;
  text-align: center;
  color: white;
  padding-bottom: 10rem;
}

.what-staking-content {
  display: flex;
}

.what-staking-image {
  flex: 1;
}

.what-staking-image img {
  width: 500px;
  border-radius: 10px;
}

.what-staking-inside-content {
  flex: 1;
  text-align: start;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.grant-hero-section {
  display: flex;
  justify-content: center;
  align-items: center;
  padding-top: 10rem;
  padding-bottom: 10rem;
}

.terms-of-service .hero-para{
  text-align: center !important;
  font-size: 1.5rem !important;
}

@media screen and (max-width: 992px) {
  .video-background {
    left: 0;
    opacity: 0.3;
  }

  .custom-navbar {
    position: relative !important;
  }

  .grant-hero-section {
    padding-top: 3rem;
    padding-bottom: 2rem;
  }

  .donate-now-form-container {
    padding-top: 0;
    padding-bottom: 0;
  }

  .terms-of-service{
    margin-top: 0rem !important;
  }
}

@media screen and (max-width: 756px) {
  .hero-content {
    margin-top: 5rem;
    text-align: center;
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .hero-section h1, .hero-section p {
    text-align: center;
  }

  .grant-hero-section {
    flex-direction: column;
    padding-top: 2rem;
    padding-bottom: 2rem;
    gap: 2rem;
  }

  .donate-now-inside-container h1 {
    font-size: 3rem;
  }

  .donate-now-inside-container h3 {
    font-size: 1.5rem;
  }

  .terms-of-service h1 {
    font-size: 3rem;
  }
}

@media screen and (max-width: 576px) {
  .hero-content {
    margin-bottom: 5rem;
  }

  .membership-resources h2 {
    font-size: 3rem;
  }

  .custom-navbar {
    padding: 10px 3rem !important;
  }

  .donate-now-inside-container h1 {
    font-size: 2.5rem;
  }

  
.terms-of-service h1{
  font-size: 2.5rem;
}
.terms-of-service .hero-para{
  font-size: 1rem !important;
}

.terms-of-service{
  margin: 2rem;
}
}

@media screen and (max-width: 434px) {
  .membership-resources h2 {
    font-size: 2.5rem;
  }

  .custom-navbar {
    padding: 10px 2rem !important;
  }

  .security-section h2 {
    font-size: 1.8rem;
  }

  .donate-now-inside-container h1 {
    font-size: 1.8rem;
  }
  .donate-now-inside-container h3 {
    font-size: 1rem;
  }

  .terms-of-service h1{
    font-size: 2rem;
  }

  .terms-of-service{
    margin: 1rem;
  }
}