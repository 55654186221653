.statistics-container {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
}
    
.statistics-container  .statistics-grid {
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;
    border-radius: 15px;
    width: 100%;
}
    
.statistics-container .overview-card {
    flex-basis: 250px;
    flex-grow: 1;
    margin: 5px;
    display: flex;
    align-items: flex-start;
    flex-direction: column;
    justify-content: space-between;
    padding: 30px;
    min-height: 120px;
    background: rgba(255, 255, 255, .1);
    border-radius: 15px;
    position: relative;
    box-sizing: padding-box;
}    
.statistics-container .overview-card:after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(42, 109, 255, .2);
    filter: blur(30px);
    width: 100%;
    height: 100%;
    z-index: 1;
}

.statistics-container .overview-card.green::after {
    background: rgba(20, 241, 149, .2);
}
    
.statistics-container .overview-card .card-title {
    font-size: 1rem;
    text-transform: uppercase;
    font-weight: 600;
    margin: 0 0 3px 0;
    line-height: 100%;
    color: white;
}

.statistics-container .overview-card .card-value {
    font-size: 1.7rem;
    font-weight: 200;
    line-height: 100%;
    letter-spacing: -.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}