@keyframes steam {
    0% {
        background-position: 0 0;
    }
    50% {
        background-position: 400% 0;
    }
    100% {
        background-position: 0 0;
    }
}
.purchase-container {
    width: 100%;
    display: grid;
    grid-template-rows: auto 1fr;
    height: 100%;
}
.purchase-container .card-title-wrapper {
    display: flex;
    flex-direction: row;
    align-items: center;
}
.purchase-container .card-title-wrapper .card-title {
    margin: 0 40px 0 0;
    white-space: nowrap;
    color: white;
}

.purchase-container .card-title-wrapper .token-card-price {
    font-size: 2.2rem;
    font-weight: 100;
    line-height: 100%;
    letter-spacing: -.03em;
    display: flex;
    flex-direction: row;
    align-items: center;
    color: white;
}

.purchase-container .card-content {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    width: 100%;
    height: 100%;
    min-height: 180px;
}

.purchase-container .card-content-wrapper {
    padding: 30px 0 0 0;
    width: 100%;
    min-width: 180px;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-items: center;     
}
                  