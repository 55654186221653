.portfolio-container {
    margin: 5px;
    display: grid;
    grid-gap: 10px;
}

.portfolio-container .card-container {
    padding: 30px;
    background: rgba(0, 0, 0, .1);
    border-radius: 15px;
    display: flex;
    position: relative;
    align-items: flex-start;
}
.portfolio-container .card-container.my-portfolio {
    flex-direction: column;
    grid-area: 'card2';
}
 
.portfolio-container .card-container.my-portfolio::after {
    position: absolute;
    content: '';
    top: 0;
    left: 0;
    bottom: 0;
    right: 0;
    background: rgba(42, 109, 255, .1);
    filter: blur(30px);
    width: 100%;
    height: 100%;
    z-index: -1;
}
        

.portfolio-container .card-container.purchase {
    display: flex;
    flex-direction: column;
    position: relative;
    height: 100%;
    grid-area: 'card1';
    width: 100%;
}
    